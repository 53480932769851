export const NAME = 'dashboard';
export const ALL_DASHBOARD_MEASUREMENTS = [
    {value: 'ordersCount', label: 'Orders Count'},
    {value: 'revenue', label: 'Revenue'}
];

export const DASHBOARD_ITEMS = {
    Orders1: 0,
    Orders2: 1,
    Orders3: 2,
    Orders4: 3,
    Sales1: 4,
    Sales2: 5,
    Sales3: 6,
    Sales4: 7,
    Users1: 8,
    Users2: 9,
    Users3: 10,
    Orders5: 11,
    Sales5: 12,
    Inventory1: 13,
    Inventory2: 14,
    Inventory3: 15,
    Inventory4: 16,
    Inventory5: 17,
    Orders6: 18,
    Sales6: 19
};


export const USER_ACTIONS =
    [
        {label: "Order Processed", value: "ORDER_PROCESSED"},
        {label: "Shipping label printed", value: "SHIPPING_LABEL_"},
        {label: "Invoice Printed", value: "INVOICE_PRINTED"},
        {label: "Packaging List Printed", value: "PACKAGING_LIST"},
        {label: "Pick List Printed", value: "PICK_LIST_PRINT"},
        {label: "Order Processed (value)", value: "ORDER_PROCESSED_VALUE"},
    ];

export const INVENTORY_TYPES = {
    any: {
        label: "All",
        value: null
    },
    parent: {
        label: "Variations",
        value: 1
    }
}