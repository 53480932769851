import React, {Component} from 'react';
import {PropagateLoader} from 'react-spinners';

class Loader extends Component {
    render() {
        return (
            <div style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <PropagateLoader sizeUnit={'px'}
                            size={this.props.size || 20}
                            color={'#123abc'}
                            loading={this.props.loading}/>
            </div>);

    }
}


export default Loader;
