import React from 'react';
import _ from "lodash";
import { connect } from "react-redux";
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { sendFeedback, sendFeedbackMobile } from "../actions";
import * as selectors from "../selectors";
import './feedback.css';

class Feedback extends React.PureComponent {
    state = {
        text: '',
        mobile: null,
        isSentFeedback: false,
        isSentFeedbackMobile: false,
    };

    componentDidUpdate(prevProps) {
        if (prevProps.isSendingFeedback && !this.props.isSendingFeedback) {
            this.setState({ isSentFeedback: true });
        }
        if (prevProps.isSendingFeedbackMobile && !this.props.isSendingFeedbackMobile) {
            this.setState({ isSentFeedbackMobile: true });
        }
    }

    onChangeFeedback = e => this.setState({ text: e.target.value });

    onSendFeedback = e => {
        this.props.sendFeedback({ text: this.state.text, useMobile: this.state.mobile });
    };

    onChangeMobile = e => this.setState({ mobile: e.target.value === 'true' });

    onSendMobile = e => {
        this.props.sendFeedbackMobile({ value: this.state.mobile });
    };

    get renderThankMessage() {
        return (
            <p className="thankMessage">Thanks for your feedback!</p>
        );
    }

    render() {
        const { mobile, text, isSentFeedback, isSentFeedbackMobile } = this.state;
        return (
            <Form>
                {isSentFeedback ?
                    this.renderThankMessage :
                    <>
                        <FormGroup>
                            <Label for="feedackText">Please provide your feedback for us</Label>
                            <Input type="textarea" rows={5} name="text" id="feedackText" onChange={this.onChangeFeedback}/>
                        </FormGroup>
                        <FormGroup tag="fieldset">
                            <legend className="legendMobile">Do you want to use mobile dashboards?</legend>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="radio"
                                        name="radio1"
                                        value={true}
                                        onChange={this.onChangeMobile}
                                    />{' '}Yes
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="radio"
                                        name="radio1"
                                        value={false}
                                        onChange={this.onChangeMobile}
                                    />{' '}No
                                </Label>
                            </FormGroup>
                        </FormGroup>
                        <Button onClick={this.onSendFeedback} className="sendButton" disabled={!text || !_.isBoolean(mobile)}>Send</Button>
                    </>
                }
            </Form>
        )
    }
}

function mapStateToProps(state) {
    return {
        isSendingFeedback: selectors.isSendingFeedback(state),
        isSendingFeedbackMobile: selectors.isSendingFeedbackMobile(state),
    };
}
const mapDispatchToProps = {
    sendFeedback, sendFeedbackMobile
};

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
