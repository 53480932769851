import axios from 'axios';
import _ from 'lodash';
import * as coreConstants from './constants';
import * as selectors from '../Auth/selectors';

const configAxios = store => {
    axios.defaults.baseURL = coreConstants.BASE_URL;
    axios.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8';
    axios.defaults.headers.common.Pragma = 'no-cache';

    axios.interceptors.request.use(async config => {
        const accessToken = selectors.getAuthorizeToken(store.getState());
        if (!accessToken) {
            return updateAuthorizationHeader(config);
        }

        return updateAuthorizationHeader(config, accessToken);
    }, error => Promise.reject(error));

    axios.interceptors.response.use(response => response,
        error => {
            if (error && _.get(error, 'response.status') === 401) {
                console.log('NOT AUTHORIZED!');
                throw new Error('NOT AUTHORIZED');
            }
            throw error;
        }
    );
};

const updateAuthorizationHeader = (config, accessToken = '') => {
    const c = config;
    if (accessToken) {
        c.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
        c.headers.common = _.omit(c.headers.common, ['Authorization']);
    }
    return c;
};

export default configAxios;