import React, { useEffect, useState } from 'react';
import _ from "lodash";
import { connect } from "react-redux";
import { Col, Grid, Row } from "react-flexbox-grid";
import { getSettings, setSettings } from "../actions";
import * as selectors from "../selectors";
import './form.css';

const Settings = props => {

    const handleSubmit = e => {
        e.preventDefault();
        props.setSettings({
            useVat
        });
    }

    const [useVat, setUseVat] = useState(false);

    useEffect(() => {
        props.getSettings();
    }, []);

    useEffect(() => {
        setUseVat(_.get(props.settings, "useVat", false));
    }, [props.settings]);

    const toggleVat = e => {
        const { checked } = e.target;
        setUseVat(checked);
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid fluid>
                <Row className="group">
                    General
                </Row>
                <Row className="input-container">
                    <Col>
                        <label>Use VAT in calculations:</label>
                    </Col>
                    <Col>
                        <input type="checkbox" name="name" checked={useVat} onChange={toggleVat}/>
                    </Col>
                </Row>
                <Row>
                    <input type="submit" value="Save Changes" />
                </Row>
            </Grid>
        </form>
    )
}

function mapStateToProps(state) {
    return {
        settings: selectors.getSettings(state)
    };
}

const mapDispatchToProps = {
    getSettings,
    setSettings
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
