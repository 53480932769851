import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route} from 'react-router';
import _ from 'lodash';
import Dashboard from './Modules/Dashboard/Components/Dashboard';
import SignIn from './Modules/Auth/Components/SignIn';
import * as authSelectors from './Modules/Auth/selectors';
import {authorize} from './Modules/Auth/actions';

class App extends Component {

    componentWillMount() {
        this.redirect();
    }

    componentDidUpdate() {
        this.redirect();
    }

    redirect() {
        if(!this.props.token && !window.location.href.includes('login'))  {
            window.location.href = "/login"; 
        } 
        if(this.props.token && window.location.pathname !== "/")
        {
            window.location.href = "/";
        } 
    }

    render() {
        const {authorizing, token} = this.props;
        return (
            <div>
                {authorizing || !token 
                    ? <Route exact path='/login' component={SignIn}/>
                    : <Route exact path='/' component={Dashboard} />
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const token = authSelectors.getAuthorizeToken(state);
    const tokenTtl = authSelectors.getAuthorizeTokenTtl(state);
    const authorizing = authSelectors.isAuthorizing(state);
    return {
        token,
        tokenTtl,
        authorizing
    };
}

export default connect(mapStateToProps, {authorize})(App);
