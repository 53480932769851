import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { NAME } from './constants';
import * as actionTypes from './actionTypes';

const initialState = {
    token: null,
    user: null,
    tokenTtl: null,
    authorizing: false,
    redirectToOld: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTHORIZE_BY_APPLICATION.REQUEST:
            return {
                ...state,
                authorizing: true,
            };
        case actionTypes.AUTHORIZE_BY_APPLICATION.SUCCESS:
            return {
                ...state,
                token: action.payload.token,
                user: action.payload.user,
                authorizing: false
            };
        case actionTypes.AUTHORIZE_BY_APPLICATION.ERROR:
            return {
                ...state,
                token: null,
                user: null,
                tokenTtl: null,
                redirectToOld: null,
                authorizing: false
            };
        default:
            return state;
    }
};

const persistConfig = {
    key: NAME,
    storage,

};

export default persistReducer(persistConfig, reducer);