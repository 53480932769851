import React from 'react';
import _ from "lodash";
import { UncontrolledTooltip } from 'reactstrap';
import './chartCheckboxItem.css';

class ChartCheckboxItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          id: _.uniqueId("item")
        };
    }

    onClick = () => {
        this.props.onClick(this.props.item);
    };

    renderTooltip = t => {
        return <p>{t}</p>
    };

    render() {
        const { item, isSelected } = this.props;
        return (
            <div id={this.state.id} className={`chartCheckbox ${isSelected ? 'chartCheckbox--selected' : ''}`}                
                onClick={this.onClick}>
                {item[this.props.textDisplayKey] || 'NONAME'}
                {item.toolTips ? (
                    <UncontrolledTooltip placement="top" target={this.state.id}>{_.map(item.toolTips, this.renderTooltip)}</UncontrolledTooltip>
                ) : null}                
            </div>
        );
    }
}

export default ChartCheckboxItem;