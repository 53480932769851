import React, { Component } from "react";
import { authorize } from '../actions';
import {connect} from 'react-redux';
import * as authSelectors from '../selectors';

class SignIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
          email: "",
          apiKey: ""
        };
      }

    handleLogin() {
        this.props.authorize({ ...this.state })
    };

    setEmail(event) {
        this.setState({
          email: event.target.value
        });
    }

    setApiKey(event) {
        this.setState({
          apiKey: event.target.value
        });
    }

    render () {
        return (
        <div style={{ display: 'flex', justifyContent: 'center', height: '100vh', alignItems: 'center'}}>
            <div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <h1>Sign In</h1>
                </div>

                <div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <input
                            placeholder='email'
                            value={this.state.email} 
                            onChange={event => this.setEmail(event)}
                            style={{ padding: 10, marginBottom: 15 }}
                            required={true}
                        />
                    </div>
                    <div>
                        <input
                            placeholder='api key'
                            value={this.state.apiKey} 
                            onChange={event => this.setApiKey(event)}
                            style={{ padding: 10, marginBottom: 15 }}
                            required={true}
                            type="password"
                        />
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <button
                            type="button"
                            onClick={() => this.handleLogin()}>
                           Login
                        </button>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}
function mapStateToProps(state) {
    const authorizing = authSelectors.isAuthorizing(state);
    return {
        authorizing
    };
}


export default connect(mapStateToProps, {authorize})(SignIn);

// const styles = StyleSheet.create({
//     mainblock: {
//         background: "#fff",
//         width: 350,
//         padding: 15,
//         borderRadius: 6
//     },
//     headerbar: {
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//         alignItems: "center"
//     },
//     headerbarLogo: {
//         height: 45
//     },
//     actionsWraper: {
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center"
//     },
//     button: {
//         width: "100%",
//         padding: "9px 12px",
//         height: "auto"
//     }
// });
