import * as actionTypes from './actionTypes';
import * as api from './api';

export const getSourceStatsByDateRange = (start, end) => async dispatch => {
    dispatch({ type: actionTypes.GET_SOURCE_STATS_BE_DATE_RANGE.REQUEST });
    return api.getSourceStatsByDateRange(start, end)
        .then(response => {
            dispatch({
                type: actionTypes.GET_SOURCE_STATS_BE_DATE_RANGE.SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            dispatch({ type: actionTypes.GET_SOURCE_STATS_BE_DATE_RANGE.ERROR });
        });
};

export const getDailySourceStatsByDateRange = (start, end) => async dispatch => {
    dispatch({ type: actionTypes.GET_DAILY_SOURCE_STATS_BE_DATE_RANGE.REQUEST });
    return api.getDailySourceStatsByDateRange(start, end)
        .then(response => {
            dispatch({
                type: actionTypes.GET_DAILY_SOURCE_STATS_BE_DATE_RANGE.SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            dispatch({ type: actionTypes.GET_DAILY_SOURCE_STATS_BE_DATE_RANGE.ERROR });
        });
};

// export const getOrderTotalsByCountries = (start, end) => async dispatch => {
//     dispatch({ type: actionTypes.GET_ORDERS_TOTALS_BY_COUNTRIES.REQUEST });
//     return api.getOrdersTotalsByCountries(start, end)
//         .then(response => {
//             dispatch({
//                 type: actionTypes.GET_ORDERS_TOTALS_BY_COUNTRIES.SUCCESS,
//                 payload: response.data
//             });
//         })
//         .catch(error => {
//             dispatch({ type: actionTypes.GET_ORDERS_TOTALS_BY_COUNTRIES.ERROR });
//         });
// };

export const getYearlySourceStats = (data) => async dispatch => {
    dispatch({ type: actionTypes.GET_YEARLY_SOURCE_STATS.REQUEST });
    return api.getYearlySourcesStats(data)
        .then(response => {
            dispatch({
                type: actionTypes.GET_YEARLY_SOURCE_STATS.SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            dispatch({ type: actionTypes.GET_YEARLY_SOURCE_STATS.ERROR });
        });
};

export const sourceSelected = (data) => async dispatch => {
    dispatch({ type: actionTypes.SOURCE_CHANGED.SUCCESS, payload: data });
};

export const measurementSelected = (data) => async dispatch => {
    dispatch({ type: actionTypes.MEASUREMENT_CHANGED.SUCCESS, payload: data });
};


//USERS
export const getUsersReportFor6Months = (data) => async dispatch => {
    dispatch({ type: actionTypes.GET_6_MONTH_USER_STATS.REQUEST });
    return api.getUsersReportFor6Months(data)
        .then(response => {
            dispatch({
                type: actionTypes.GET_6_MONTH_USER_STATS.SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            dispatch({ type: actionTypes.GET_6_MONTH_USER_STATS.ERROR });
        });
};

export const getUserStats7Days = (data) => async dispatch => {
    dispatch({ type: actionTypes.GET_7_Days_USER_STATS.REQUEST });
    return api.getUserStats7Dayss(data)
        .then(response => {
            dispatch({
                type: actionTypes.GET_7_Days_USER_STATS.SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            dispatch({ type: actionTypes.GET_7_Days_USER_STATS.ERROR });
        });
};

export const getUserStats12hours = (data, option) => async dispatch => {
    dispatch({ type: actionTypes.GET_12_HOURS_USER_STATS.REQUEST });
    return api.getUserStats12hours(data, option)
        .then(response => {
            dispatch({
                type: actionTypes.GET_12_HOURS_USER_STATS.SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            dispatch({ type: actionTypes.GET_12_HOURS_USER_STATS.ERROR });
        });
};

//USERS


// SETTINGS

export const getSettings = () => async dispatch => {
    dispatch({ type: actionTypes.GET_SETTINGS.REQUEST });
    try {
        const response = await api.getSettings();
        dispatch({
            type: actionTypes.GET_SETTINGS.SUCCESS,
            payload: response.data
        });
    }
    catch (error) {
        dispatch({ type: actionTypes.GET_SETTINGS.ERROR });
    }
};

export const setSettings = data => async dispatch => {
    dispatch({ type: actionTypes.SET_SETTINGS.REQUEST });
    try {
        await api.setSettings(data);
        dispatch({
            type: actionTypes.SET_SETTINGS.SUCCESS,
            payload: data
        });
    }
    catch (error) {
        dispatch({ type: actionTypes.GET_SETTINGS.ERROR });
    }
};

// SETTINGS


// INVENTORY

export const getInventoryTop10ForYear = (type) => async dispatch => {
    dispatch({ type: actionTypes.GET_INVENTORY_TOP_10_FOR_YEAR.REQUEST });
    try {
        const response = await api.getInventoryTop10ForYear(type);
        dispatch({
            type: actionTypes.GET_INVENTORY_TOP_10_FOR_YEAR.SUCCESS,
            payload: response.data
        });
    }
    catch (error) {
        dispatch({ type: actionTypes.GET_INVENTORY_TOP_10_FOR_YEAR.ERROR });
    }
};

export const getInventoryTop10ThisMonth = (type) => async dispatch => {
    dispatch({ type: actionTypes.GET_INVENTORY_TOP_10_THIS_MONTH.REQUEST });
    try {
        const response = await api.getInventoryTop10ThisMonth(type);
        dispatch({
            type: actionTypes.GET_INVENTORY_TOP_10_THIS_MONTH.SUCCESS,
            payload: response.data
        });
    }
    catch (error) {
        dispatch({ type: actionTypes.GET_INVENTORY_TOP_10_THIS_MONTH.ERROR });
    }
};

export const getInventorySoldStats = (from, to, type) => async dispatch => {
    dispatch({ type: actionTypes.GET_INVENTORY_SOLD_STATS.REQUEST });
    try {
        const response = await api.getInventorySoldStats(from, to, type);
        dispatch({
            type: actionTypes.GET_INVENTORY_SOLD_STATS.SUCCESS,
            payload: response.data
        });
    }
    catch (error) {
        dispatch({ type: actionTypes.GET_INVENTORY_SOLD_STATS.ERROR });
    }
};

// INVENTORY


export const getDashboardLayout = () => dispatch => {
    dispatch({ type: actionTypes.GET_DASHBOARD_LAYOUT.REQUEST });
    return api.dashboardLayout()
        .then(response => {
            dispatch({
                type: actionTypes.GET_DASHBOARD_LAYOUT.SUCCESS,
                payload: {
                    data: response.data
                }
            });
        })
        .catch(error => {
            dispatch({ type: actionTypes.GET_DASHBOARD_LAYOUT.ERROR });
        });
};
export const updateDashboardLayout = items => dispatch => {
    dispatch({ type: actionTypes.UPDATE_DASHBOARD_LAYOUT.REQUEST });
    return api.updateDashboardLayout(items)
        .then(response => {
            dispatch({
                type: actionTypes.UPDATE_DASHBOARD_LAYOUT.SUCCESS,
                payload: {
                    data: items
                }
            });
        })
        .catch(error => {
            dispatch({ type: actionTypes.UPDATE_DASHBOARD_LAYOUT.ERROR });
        });
};



export const sendFeedback = data => dispatch => {
    dispatch({ type: actionTypes.SEND_FEEDBACK.REQUEST });
    return api.sendFeedback(data)
        .then(() => {
            dispatch({ type: actionTypes.SEND_FEEDBACK.SUCCESS });
        })
        .catch(() => {
            dispatch({ type: actionTypes.SEND_FEEDBACK.ERROR });
        });
};
export const sendFeedbackMobile = data => dispatch => {
    dispatch({ type: actionTypes.SEND_FEEDBACK_MOBILE.REQUEST });
    return api.sendFeedbackMobile(data)
        .then(() => {
            dispatch({ type: actionTypes.SEND_FEEDBACK_MOBILE.SUCCESS });
        })
        .catch(() => {
            dispatch({ type: actionTypes.SEND_FEEDBACK_MOBILE.ERROR });
        });
};