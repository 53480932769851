import React from 'react';
import _ from 'lodash';
import { CSVLink } from 'react-csv';
import { ModalBody, Button, Modal, Table, Row, Col } from 'reactstrap';
import ChartCheckboxItem from '../ChartCheckboxItem/ChartCheckboxItem';
import './сhartWrapper.css';
import Select from 'react-select';

class ChartWrapper extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            items: props.data,
            isOpenTotal: false
        };
        const reportRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.data, this.props.data)) {
            this.setState({ items: this.props.data }, this.callback);
        }
    }

    onToggleTotal = () => this.setState(prevState => ({ isOpenTotal: !prevState.isOpenTotal }));

    isSelected = item => {
        return !!_.find(this.state.items, i => i[this.props.textDisplayKey] === item);
    };

    callback = () => {
        this.props.onSelectItems(this.state.items);
    };

    onClickCheckbox = item => {
        if (this.isSelected(item[this.props.textDisplayKey])) {
            this.setState(prevState => ({ items: _.filter(prevState.items, it => it[this.props.textDisplayKey] !== item[this.props.textDisplayKey]) }), this.callback);
        } else {
            this.setState(prevState => ({ items: [...prevState.items, item] }), this.callback);
        }
    };

    renderCheckboxItem = item => {
        return (
            <ChartCheckboxItem
                key={item[this.props.textDisplayKey]}
                item={item}
                isSelected={this.isSelected(item[this.props.textDisplayKey])}
                onClick={this.onClickCheckbox}
                textDisplayKey={this.props.textDisplayKey}
            />
        );
    };

    get totalSum() {
        const { items } = this.state;
        return _.round(_.sum(_.map(items, item => _.sumBy(item.data, i => i.y))), 2);
    }

    get reportData() {
        const { reportData, data } = this.props;
        return reportData ? reportData : data;
    }

    render() {
        const { children, title, data, options, selectedOption, description, total, hasTotal } = this.props;
        const { isOpenTotal, items } = this.state;
        console.log(items);
        return (
            <div className="chartWrapper">
                <div className="chartWrapperTitleContainer">
                    <p>{title} {description ? <span>{description}</span> : null}</p>
                    {options ? (
                        <Col md={3}>
                            <Select options={options}
                                onChange={this.props.onOptionSelected}
                                placeholder={"Select Range"}
                                value={selectedOption}
                            />
                        </Col>
                    ) : null}
                    <span className="downloadIcon">
                        <CSVLink data={this.reportData} filename={`${title}.csv`}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                className="feather feather-download">
                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                <polyline points="7 10 12 15 17 10"></polyline>
                                <line x1="12" y1="15" x2="12" y2="3"></line>
                            </svg>
                            <span>Download (CSV file)</span>
                        </CSVLink>
                    </span>
                </div>
                {total ? (
                    <div className="chartWrapperTitleContainer">
                        <p>Total: {Math.round(total * 100) / 100}</p>
                    </div>
                ) : null}
                <div className="chartWrapperItems">
                    {_.map(data, this.renderCheckboxItem)}
                </div>
                <div className="chartWrapperContent">
                    {children}
                </div>

                {hasTotal ?
                    <>
                        <Button
                            color="primary"
                            onClick={this.onToggleTotal}
                            className="chartWrapper-totalButton">
                            Show total details
                        </Button>
                        <Modal isOpen={isOpenTotal} toggle={this.onToggleTotal}>
                            <ModalBody>
                                <Table striped={true}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {_.map(items, item => {
                                            return (
                                                <tr>
                                                    <td>{item.id}</td>
                                                    <td>{_.round(_.sumBy(item.data, i => i.y), 2)}</td>
                                                </tr>
                                            );
                                        })}
                                        <tr>
                                            <th scope="row">Total</th>
                                            <th scope="row">{this.totalSum}</th>
                                        </tr>
                                    </tbody>
                                </Table>
                            </ModalBody>
                        </Modal>
                    </> : null
                }
            </div>
        );
    }
}

export default ChartWrapper;