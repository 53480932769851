import React, {Component} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { connect } from "react-redux";
import Sales from './Sales';
import Orders from './Orders';
import Users from './Users';
import Settings from './Settings';
import Inventory from './Inventory';
import Feedback from './Feedback';
import 'react-daterange-picker/dist/css/react-calendar.css';
import './dashboard.css';
import "react-tabs/style/react-tabs.css";
import {getDashboardLayout} from "../actions";
import './DateRange/styles.scss'; // main style file
import './DateRange/theme/default.scss'; // main style file

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.props.getDashboardLayout();
    }

    render() {
        return (
            <Tabs>
                <TabList>
                    <Tab>Orders</Tab>
                    <Tab>Sales</Tab>
                    {/* <Tab>User Performance</Tab>
                    <Tab>Inventory Performance</Tab>
                    <Tab>Settings</Tab>*/}
                    <Tab>Feedback</Tab> 
                </TabList>
                <TabPanel>
                    <Orders />
                </TabPanel>
                <TabPanel>
                    <Sales />
                </TabPanel>
                {/* <TabPanel>
                    <Users />
                </TabPanel>
                <TabPanel>
                    <Inventory />
                </TabPanel>
                <TabPanel>
                    <Settings />
                </TabPanel>*/}
                <TabPanel>
                    <Feedback />
                </TabPanel> 
            </Tabs>
        );
    }
}

export default connect(null, { getDashboardLayout })(Dashboard);